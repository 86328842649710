import React, { Fragment } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "mdbreact";
import MainHeader from "../core/main/MainHeader";
import axios from "axios";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

const dbUrl = "https://phode-back.herokuapp.com";

class UserView extends React.Component {
  state = {
    isLoading: true,
    userLogged: "demo", // Remplacer dynamiquement
  };

  logout() {
    Cookies.remove("id");
    Cookies.remove("username");
    Cookies.remove("role");
    Cookies.remove("token");
    Cookies.remove("role");
    Cookies.remove("lang");
    window.location.href = "/";
  }

  render() {
    if (this.state.isLoading === false) {
      console.log(this.state);
      return (
        <div id="user" className="page">
          <MainHeader
            title={this.state.firstName + " " + this.state.lastName}
          />
          <Container className="ml-0">
            <div id="userMenu" className="pt-3 mb-1" />
            <div id="user-content" className="pb-5">
              <Card>
                <CardBody>
                  <div id="edit-menu" className="d-flex mb-4">
                    <div className="mr-3">
                      <Link
                        to={
                          "/users/" +
                          localStorage.getItem("extranet_phode_id") +
                          "/view"
                        }
                        className="active"
                      >
                        {this.state.translationsGlobal.see[currentLanguage]}
                      </Link>
                    </div>
                    <div>
                      <Link
                        to={
                          "/users/" +
                          localStorage.getItem("extranet_phode_id") +
                          "/edit"
                        }
                      >
                        {this.state.translationsGlobal.edit[currentLanguage]}
                      </Link>
                    </div>
                  </div>
                  <h2 className="mb-3">
                    {this.state.translationsUsers.infoPerso[currentLanguage]}
                  </h2>
                  <table>
                    <tr>
                      <td>
                        {this.state.translationsUsers.name[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        {this.state.firstName + " " + this.state.lastName}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.position[currentLanguage]}
                      </td>
                      <td> {this.state.profession}</td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.phone[currentLanguage]}
                      </td>
                      <td> {this.state.phoneOne}</td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.cell[currentLanguage]}
                      </td>
                      <td> {this.state.phoneTwo}</td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.email[currentLanguage]}
                      </td>
                      <td> {this.state.email}</td>
                    </tr>
                  </table>
                  <h2 className="mt-3">
                    {this.state.translationsUsers.infoPro[currentLanguage]}
                  </h2>
                  <p className="mb-3">
                    {this.state.translationsUsers.customize[currentLanguage]}
                  </p>
                  <table>
                    <tr>
                      <td>
                        {
                          this.state.translationsUsers.companyName[
                            currentLanguage
                          ]
                        }
                      </td>
                      <td> {this.state.companyName}</td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.address[currentLanguage]}
                      </td>
                      <td> {this.state.companyAddress}</td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.country[currentLanguage]}
                      </td>
                      <td> {this.state.companyCountry}</td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.logo[currentLanguage]}
                      </td>
                      <td id="thumbnail">
                        <img
                          src={
                            process.env.REACT_APP_FILES_URL +
                            this.state.companyLogo
                          }
                        />
                      </td>
                    </tr>
                  </table>
                  <Button color="red" className="mt-5" onClick={this.logout}>
                    {this.state.translationsGlobal.logout[currentLanguage]}
                  </Button>
                </CardBody>
              </Card>
            </div>
          </Container>
          <style jsx>{`
            #edit-menu a:after {
              content: "";
              display: block;
              height: 5px;
              width: 100%;
            }
            #edit-menu a.active {
              color: var(--green);
            }
            #edit-menu a.active:after {
              background: var(--green);
            }
            #edit-menu a {
              color: var(--grayText);
            }
            #edit-menu a:after {
              background: var(--grayText);
            }
            h2 {
              font-weight: bold;
            }
            #userMenu {
              border-bottom: 1px solid #f0f0f0;
              padding: 10px 0;
            }
            #userMenu a {
              color: black;
            }
            #userMenu li {
              margin-right: 15px;
            }
            table {
              width: 100%;
            }
            table td {
              border: 1px solid #f0f0f0;
              padding: 10px 20px;
            }
            table > tr > td:first-child {
              width: 20%;
            }
            #thumbnail img {
              height: auto;
              width: 200px;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "view/users/" +
            localStorage.getItem("extranet_phode_id")
        ),
        axios.get(process.env.REACT_APP_API_URL + "translations/users"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
      ])
      .then(
        axios.spread((response, translationsUsers, translationsGlobal) => {
          this.setState({
            isLoading: false,
            ...response.data,
            translationsUsers: translationsUsers.data[0].translations,
            translationsGlobal: translationsGlobal.data[0].translations,
          });
        })
      )
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default UserView;
