import Modal from "react-modal";
import moment from "moment";
import React from "react";
import ReactDOM from "react-dom";
import { Container, Row, Col } from "mdbreact";
import MainHeader from "../core/main/MainHeader";
import DashboardElement from "./DashboardElement";
import "./dashboard.css";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class Dashboard extends React.Component {
  state = {
    isLoading: true,
    translations: "",
  };

  render() {
    const translation = this.state.translations.dashboard;
    if (this.state.isLoading === false) {
      return (
        <div id="dashboard">
          <MainHeader title="dashboard" />
          <div id="mainContent">
            <Container>
              <Row id="dashboardElements">
                <Col sm="6">
                  <DashboardElement
                    title={translation.news[currentLanguage]}
                    id="news"
                    className="dashboardElement"
                  />
                </Col>
                <Col sm="6">
                  <DashboardElement
                    title={translation.documents[currentLanguage]}
                    id="documents"
                    className="dashboardElement"
                  />
                </Col>
                <Col sm="6">
                  <DashboardElement
                    title={translation.medias[currentLanguage]}
                    id="medias"
                    className="dashboardElement"
                  />
                </Col>
                {Cookies.get("role") === "admin" ? (
                  <Col sm="6">
                    <DashboardElement
                      title={translation.users[currentLanguage]}
                      id="users"
                      className="dashboardElement"
                    />
                  </Col>
                ) : null}
              </Row>
            </Container>
          </div>
          <style jsx>{`
            h1 {
              margin-top: 60px;
              text-align: center;
            }

            @media only screen and (max-width: 767px) {
              .dashboardElement {
                height: auto;
              }
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_URL + "translations/dashboard")
      .then((response) => {
        this.setState({
          isLoading: false,
          translations: {
            dashboard: response.data[0].translations,
          },
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    // Save login time
    if (localStorage.getItem("extranet_phode_id")) {
      axios
        .post(
          process.env.REACT_APP_API_URL +
            "lastconnexion/" +
            localStorage.getItem("extranet_phode_id"),
          {
            lastConnexion: new Date(),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {})
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    }
  }
}

export default Dashboard;
