import Dropzone from "react-dropzone";
import SelectDropdown from "../Select";
import React, { Fragment } from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "mdbreact";
import { Link } from "react-router-dom";
import MainHeader from "../core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
import Select from "react-select";
const currentLanguage = Cookies.get("lang");

class DocumentEdit extends React.Component {
  state = {
    brands: "",
    documentCategories: "",
    documentType: "",
    species: "",

    currentLanguage: "",
    isLoading: true,
    message: "",
    thisElement: "",
    translations: ""
  };

  filterValue = (filter, filterData) => {
    const filtersState = {
      brands: "brands",
      documentscategories: "documentCategories",
      species: "species",
      types: "documentType"
    };
    let filterKey = filtersState[filter];
    this.setState({
      [filterKey]: filterData
    });
    console.log({ [filterKey]: filterData });
  };

  onDrop = files => {
    files.forEach(file => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          upload: {
            uploadData: fileAsBinaryString,
            uploadName: files[0].name
          }
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState({
      [event.target.name]: event.target.value
    });
    if (event.target.name === "customised") {
      this.setState({
        customised: event.target.checked
      });
    }
    if (event.target.name === "size") {
      this.setState({
        a3: event.target.checked
      });
    }
  };

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({
      message: "Saving..."
    });
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "documents/edit/" +
          this.props.match.params.id,
        {
          ...this.state,
          ...this.state.upload
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        // handle success
        setTimeout(() => {
          this.setState({
            message: response.data.message + " !"
          });
        }, 1000);
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  delete = ev => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "documents/delete/" + this.state._id,
        {
          _id: this.state._id
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        // handle success
        window.location.replace("/documents");
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  filterChange = (filter, data) => {
    if (filter === "search") {
      data = data.target.value;
    }
    this.setState({
      [filter + "Selection"]: {
        data: data,
        name: filter
      }
    });
  };

  checkTranslation = (code, lang) => {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "checktranslation/documents/" +
          this.state._id +
          "?lang=" +
          lang
      )
      .then(function(response) {
        if (response.data.length > 0) {
          alert("déjà traduit");
        } else {
          window.open(
            "/documents/create?code=" + code + "&lang=" + lang,
            "_blank"
          );
        }
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      })
      .then(function() {
        // always executed
      });
  };

  render() {
    let info = [];
    let title = "";
    let category = "";
    let permissions = "";
    let especes = [];
    let marques = [];
    let typeSupport = [];
    console.log(this.state);
    if (this.state.isLoading === false) {
      info.push(
        <Fragment>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsDocuments.title[currentLanguage]}</h2>
            <Input
              value={this.state.title}
              onChange={this.handleChange}
              id="title"
              name="title"
            />
          </div>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsDocuments.code[currentLanguage]}</h2>
            {this.state.code}
          </div>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsDocuments.summary[currentLanguage]}</h2>
            <Input
              type="textarea"
              value={this.state.summary}
              onChange={this.handleChange}
              id="summary"
              name="summary"
            />
          </div>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsGlobal.file[currentLanguage]}</h2>
            <Dropzone onDrop={this.onDrop.bind(this)} multiple="false">
              {this.state.translationsGlobal.fileDrop[currentLanguage]}
            </Dropzone>
            {this.state.upload ? (
              <p>{this.state.upload.uploadName}</p>
            ) : (
              <a
                href={process.env.REACT_APP_FILES_URL + this.state.url}
                target="_blank"
              >
                {this.state.url}
              </a>
            )}
          </div>
          <div className="infoGroup mb-4">
            <div className="align-items-center d-flex">
              <input
                className="mr-2"
                type="checkbox"
                id="customised"
                name="customised"
                ref="check_me"
                checked={this.state.customised}
                value={this.state.customised}
                onChange={this.handleChange}
              />
              <h2 className="m-0">
                {this.state.translationsDocuments.customised[currentLanguage]}
              </h2>
            </div>
          </div>
          <div className="infoGroup mb-4">
            <div className="align-items-center d-flex">
              <input
                className="mr-2"
                type="checkbox"
                id="a3"
                name="a3"
                ref="check_me"
                value={this.state.a3}
                checked={this.state.a3}
                onChange={this.handleChange}
              />
              <h2 className="m-0">A3</h2>
            </div>
          </div>
          <div className="infoGroup mb-4">
            <h2>
              {this.state.translationsDocuments.language[currentLanguage]}
            </h2>
            <Input
              id="language"
              name="language"
              value={this.state.language}
              onChange={this.handleChange}
            />
          </div>
          <div id="trad">
            {this.state.language === "FR" ? null : (
              <Button
                color="phode-green"
                onClick={() => this.checkTranslation(this.state.code, "FR")}
              >
                Translate in French
              </Button>
            )}
            {this.state.language === "EN" ? null : (
              <Button
                color="phode-green"
                onClick={() => this.checkTranslation(this.state.code, "EN")}
              >
                Translate in English
              </Button>
            )}
            {this.state.language === "ES" ? null : (
              <Button
                color="phode-green"
                onClick={() => this.checkTranslation(this.state.code, "ES")}
              >
                Translate in Spanish
              </Button>
            )}
            <hr />
          </div>
          <div id="buttons">
            <Button color="phode-green" type="submit">
              {this.state.translationsGlobal.save[currentLanguage]}
            </Button>
            <Link to="/documents">
              <Button color="grey">
                {this.state.translationsGlobal.cancel[currentLanguage]}
              </Button>
            </Link>
            <Button color="red" onClick={this.delete}>
              {this.state.translationsGlobal.delete[currentLanguage]}
            </Button>
          </div>
          <br />
          <div id="messages">{this.state.message}</div>
        </Fragment>
      );
      if (this.state.especes) {
        this.state.especes.forEach(function(element) {
          especes.push(<li className="li-nostyle">{element}</li>);
        });
      }
      if (this.state.marques) {
        this.state.marques.forEach(function(element) {
          marques.push(<li className="li-nostyle">{element}</li>);
        });
      }
      if (this.state.typeSupport) {
        this.state.typeSupport.forEach(function(element) {
          typeSupport.push(<li className="li-nostyle">{element}</li>);
        });
      }

      title = this.state.title;
      category = this.state.category;
      permissions = this.state.permissions;

      return (
        <div id="document" className="page">
          <MainHeader title={this.state.title} />
          <div id="pageContent">
            <Container>
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col sm="8">
                    <Card>
                      <CardBody>{info}</CardBody>
                    </Card>
                  </Col>
                  <Col sm="4">
                    <Card className="mb-4">
                      <h3 className="p-4 text-white">Category*</h3>
                      <CardBody>
                        <SelectDropdown
                          type="documentscategories"
                          filterValue={this.filterValue}
                          value={this.state.documentCategories}
                        />
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <h3 className="p-4 text-white">Filters*</h3>
                      <CardBody>
                        <br />
                        <h4 className="mb-2">Espèces</h4>
                        <SelectDropdown
                          type="species"
                          filterValue={this.filterValue}
                          value={this.state.species}
                        />
                        <br />
                        <h4 className="mb-2">Marques</h4>
                        <SelectDropdown
                          type="brands"
                          filterValue={this.filterValue}
                          value={this.state.brands}
                        />
                        <br />
                        <h4 className="mb-2">Type de support</h4>
                        <SelectDropdown
                          type="types"
                          filterValue={this.filterValue}
                          value={this.state.documentType}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </form>
            </Container>
          </div>
          <style jsx>{`
            .react-file-reader-button {
              background: var(--green);
              color: white;
              cursor: pointer;
              display: inline-block;
              padding: 10px 20px;
            }
            h2 {
              font-size: 20px;
            }
            h3 {
              background: var(--blue);
              font-size: 20px;
            }
            #pageContent {
              padding: 50px 0;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "view/documents/" +
            this.props.match.params.id
        ),
        axios.get(process.env.REACT_APP_API_URL + "brands"),
        axios.get(process.env.REACT_APP_API_URL + "types"),
        axios.get(process.env.REACT_APP_API_URL + "species"),
        axios.get(process.env.REACT_APP_API_URL + "documentscategories"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
        axios.get(process.env.REACT_APP_API_URL + "translations/documents"),
        axios.get(process.env.REACT_APP_API_URL + "translations/medias"),
        axios.get(process.env.REACT_APP_API_URL + "translations/news"),
        axios.get(process.env.REACT_APP_API_URL + "translations/users")
      ])
      .then(
        axios.spread(
          (
            dataThis,
            dataBrands,
            dataTypes,
            dataSpecies,
            dataDocumentsCategories,
            dataTrGlobal,
            dataTrDocuments,
            dataTrMedias,
            dataTrNews,
            dataTrUsers
          ) => {
            console.log(dataThis);
            this.setState({
              isLoading: false,
              ...dataThis.data,
              upload: {
                uploadName: dataThis.data.url
              },
              brandsList: [...dataBrands.data],
              speciesList: [...dataSpecies.data],
              typeSupportList: [...dataTypes.data],
              documentsCategoriesList: [...dataDocumentsCategories.data],
              translationsGlobal: dataTrGlobal.data[0].translations,
              translationsDocuments: dataTrDocuments.data[0].translations,
              translationsMedias: dataTrMedias.data[0].translations,
              translationsNews: dataTrNews.data[0].translations,
              translationsUsers: dataTrUsers.data[0].translations
            });

            // Species
            let speciesData = [];
            const speciesPush = new Promise(function(resolve, reject) {
              for (let i = 0; i < dataSpecies.data.length; i++) {
                speciesData.push({
                  label: dataSpecies.data[i][currentLanguage],
                  value: dataSpecies.data[i].value
                });
              }
              resolve(speciesData);
            });
            speciesPush
              .then(response => {
                this.setState({
                  speciesList: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });

            let typesData = [];
            const typesPush = new Promise(function(resolve, reject) {
              for (let i = 0; i < dataTypes.data.length; i++) {
                typesData.push({
                  label: dataTypes.data[i][currentLanguage],
                  value: dataTypes.data[i].value
                });
              }
              resolve(typesData);
            });
            typesPush
              .then(response => {
                this.setState({
                  typesList: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });

            /* DOCUMENTS CATEGORIES */
            let documentsCategoriesData = [];
            const documentsCategoriesPush = new Promise(function(
              resolve,
              reject
            ) {
              for (let i = 0; i < dataDocumentsCategories.data.length; i++) {
                documentsCategoriesData.push({
                  label: dataDocumentsCategories.data[i][currentLanguage],
                  value: dataDocumentsCategories.data[i].value
                });
              }
              resolve(documentsCategoriesData);
            });
            documentsCategoriesPush
              .then(response => {
                this.setState({
                  documentsCategoriesList: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        )
      )
      .catch(function(error) {
        console.log(error);
      });
  }
}

export default DocumentEdit;
