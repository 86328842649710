import { stateToHTML } from "draft-js-export-html";
import {
  EditorState,
  Editor,
  convertToRaw,
  convertFromRaw,
  RichUtils
} from "draft-js";
import React, { Fragment } from "react";
import { Card, CardBody, Col, Container, Row } from "mdbreact";
import MainHeader from "../components/core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");
const moment = require("moment");

class DocumentView extends React.Component {
  state = {
    photoIndex: 0,
    isOpen: false,
    documents: [],
    documentsFiltered: [],
    isLoading: true,
    medias: [],
    news: [],
    users: [],
    brands: [],
    species: [],
    documentsCategories: [],
    currentLanguage: "",
    typesSupport: [],
    documentsCategoriesSelection: {
      data: []
    },
    speciesSelection: {
      data: []
    },
    brandsSelection: {
      data: []
    },
    mediaType: [],
    mediaTypeSelection: {
      data: []
    },
    mediaCategoriesSelection: {
      data: []
    },
    mediasTypes: [],
    mediasTypesSelection: {
      data: []
    },
    typeSupportSelection: {
      data: []
    },
    searchSelection: {
      search: []
    },
    translationsGlobal: {},
    translationsDocuments: {},
    translationsMedias: {},
    translationsNews: {},
    translationsUsers: {},
    editorState: EditorState.createEmpty(),
    rte: ""
  };

  onChange = editorState => {
    const contentState = editorState.getCurrentContent();
    console.log("content state", convertToRaw(contentState));
    this.setState({
      editorState,
      rte: JSON.stringify(convertToRaw(contentState))
    });
  };

  render() {
    let info = [];
    if (this.state.isLoading === false) {
      switch (this.props.match.params.type) {
        case "documents":
          info.push(
            <CardBody>
              <div className="infoGroup mb-4">
                <h2>Title</h2>
                <div>{this.state.thisElement.title}</div>
              </div>
              <div className="infoGroup mb-4">
                <h2>Code</h2>
                <div>{this.state.thisElement.code}</div>
              </div>
              <div className="infoGroup mb-4">
                <h2>Summary</h2>
                <div>{this.state.thisElement.summary}</div>
              </div>
              <div className="infoGroup mb-4">
                <h2>File</h2>
                <a
                  href={
                    process.env.REACT_APP_FILES_URL + this.state.thisElement.url
                  }
                >
                  {this.state.thisElement.url}
                </a>
              </div>
              <div className="infoGroup mb-4">
                <h2>Language</h2>
                <div>{this.state.thisElement.language}</div>
              </div>
            </CardBody>
          );
          break;
        case "news":
          info.push(
            <Fragment>
              <div id="image">
                <img
                  src={
                    this.state.thisElement.url
                      ? process.env.REACT_APP_FILES_URL +
                        this.state.thisElement.url
                      : process.env.REACT_APP_FILES_URL +
                        "/laboratoires-phode.jpg"
                  }
                />
              </div>
              <CardBody>
                <h1>{this.state.thisElement.title}</h1>
                <div
                  id="date"
                  className="d-flex justify-content-center mb-4 text-gray"
                >
                  {moment(this.state.thisElement.dateAdded).format(
                    "DD/MM/YYYY"
                  )}
                </div>
                <Editor
                  editorState={this.state.editorState}
                  handleKeyCommand={this.handleKeyCommand}
                  onChange={this.onChange}
                  id="editor"
                  readOnly="true"
                />
              </CardBody>
            </Fragment>
          );
          break;
        default:
          break;
      }

      return (
        <div id="document" className="page">
          <MainHeader title={this.state.thisElement.title} />
          <div id="pageContent">
            <Container>
              <Row>
                <Col sm="8">
                  <Card>{info}</Card>
                </Col>
                {this.props.match.params.type === "documents" ? (
                  <Col sm="4">
                    <Card className="mb-4">
                      <h3 className="p-4 text-white">Categories</h3>
                      <CardBody>
                        {this.state.thisElement.documentCategories
                          ? this.state.thisElement.documentCategories
                          : null}
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <h3 className="p-4 text-white">Filters</h3>
                      <CardBody>
                        <h4 className="mb-2">Species</h4>
                        {this.state.thisElement.species
                          ? this.state.thisElement.species
                          : null}
                        <h4 className="mb-2 mt-2">Brands</h4>
                        {this.state.thisElement.brands
                          ? this.state.thisElement.brands
                          : null}
                        <h4 className="mb-2 mt-2">Type</h4>
                        {this.state.thisElement.documentType
                          ? this.state.thisElement.documentType
                          : null}
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <h3 className="p-4 text-white">Permissions</h3>
                      {this.state.thisElement.permissions
                        ? this.state.thisElement.permissions
                        : null}
                      <CardBody />
                    </Card>
                  </Col>
                ) : null}
              </Row>
            </Container>
          </div>
          <style jsx>{`
            h2 {
              font-size: 20px;
            }
            h3 {
              background: var(--blue);
              font-size: 15px;
            }
            #pageContent {
              padding: 50px 0;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "view/" +
            this.props.match.params.type +
            "/" +
            this.props.match.params.id
        ),
        axios.get(process.env.REACT_APP_API_URL + "brands"),
        axios.get(process.env.REACT_APP_API_URL + "types"),
        axios.get(process.env.REACT_APP_API_URL + "species"),
        axios.get(process.env.REACT_APP_API_URL + "documentscategories"),
        axios.get(process.env.REACT_APP_API_URL + "data/mediacategories"),
        axios.get(process.env.REACT_APP_API_URL + "data/mediatypes"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
        axios.get(process.env.REACT_APP_API_URL + "translations/documents"),
        axios.get(process.env.REACT_APP_API_URL + "translations/medias"),
        axios.get(process.env.REACT_APP_API_URL + "translations/news"),
        axios.get(process.env.REACT_APP_API_URL + "translations/users")
      ])
      .then(
        axios.spread(
          (
            dataThis,
            dataBrands,
            dataTypes,
            dataSpecies,
            dataDocumentsCategories,
            dataMediaCategories,
            datamediatypes,
            dataTrGlobal,
            dataTrDocuments,
            dataTrMedias,
            dataTrNews,
            dataTrUsers
          ) => {
            this.setState({
              isLoading: false,
              thisElement: dataThis.data,
              brands: [...dataBrands.data],
              translationsGlobal: dataTrGlobal.data[0].translations,
              translationsDocuments: dataTrDocuments.data[0].translations,
              translationsMedias: dataTrMedias.data[0].translations,
              translationsNews: dataTrNews.data[0].translations,
              translationsUsers: dataTrUsers.data[0].translations
            });
            console.log(dataThis.data);

            // RTE
            if (dataThis.data.rte) {
              this.setState({
                editorState: EditorState.createWithContent(
                  convertFromRaw(JSON.parse(dataThis.data.rte))
                )
              });
            }

            // medias

            let mediatypesArray = [];
            const mediatypesPush = new Promise(function(resolve, reject) {
              for (let i = 0; i < datamediatypes.data.length; i++) {
                mediatypesArray.push({
                  label: datamediatypes.data[i][currentLanguage],
                  value: datamediatypes.data[i].value
                });
              }
              resolve(mediatypesArray);
            });
            mediatypesPush
              .then(response => {
                this.setState({
                  mediatypes: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });

            let mediaCategoriesArray = [];
            const mediaCategoriesPush = new Promise(function(resolve, reject) {
              for (let i = 0; i < dataMediaCategories.data.length; i++) {
                mediaCategoriesArray.push({
                  label: dataMediaCategories.data[i][currentLanguage],
                  value: dataMediaCategories.data[i].value
                });
              }
              resolve(mediaCategoriesArray);
            });
            mediaCategoriesPush
              .then(response => {
                this.setState({
                  mediaCategories: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });

            // Species
            let speciesData = [];
            const speciesPush = new Promise(function(resolve, reject) {
              for (let i = 0; i < dataSpecies.data.length; i++) {
                speciesData.push({
                  label: dataSpecies.data[i][currentLanguage],
                  value: dataSpecies.data[i].value
                });
              }
              resolve(speciesData);
            });
            speciesPush
              .then(response => {
                this.setState({
                  species: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });

            let typesData = [];
            const typesPush = new Promise(function(resolve, reject) {
              for (let i = 0; i < dataTypes.data.length; i++) {
                typesData.push({
                  label: dataTypes.data[i][currentLanguage],
                  value: dataTypes.data[i].value
                });
              }
              resolve(typesData);
            });
            typesPush
              .then(response => {
                this.setState({
                  types: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });

            /* DOCUMENTS CATEGORIES */
            let documentsCategoriesData = [];
            const documentsCategoriesPush = new Promise(function(
              resolve,
              reject
            ) {
              for (let i = 0; i < dataDocumentsCategories.data.length; i++) {
                documentsCategoriesData.push({
                  label: dataDocumentsCategories.data[i][currentLanguage],
                  value: dataDocumentsCategories.data[i].value
                });
              }
              resolve(documentsCategoriesData);
            });
            documentsCategoriesPush
              .then(response => {
                this.setState({
                  documentsCategories: response
                });
              })
              .catch(function(error) {
                console.log(error);
              });
          }
        )
      )
      .catch(function(error) {
        console.log(error);
      });
  }
}

export default DocumentView;
