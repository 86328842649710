import React from "react";
import MainHeader from "../core/main/MainHeader";
import List from "../lists/List";

const Users = () => (
  <div id="users">
    <MainHeader title="users" />
    <List type="users" />
    <style jsx>{`
      #mainHeader {
        height: 125px;
      }
      h1 {
        margin-top: 30px;
      }
      #listItems {
        padding: 20px;
      }
      #listItems h2 {
        font-size: 25px;
      }
      #listItems {
        padding: 20px;
      }
    `}</style>
  </div>
);

export default Users;
