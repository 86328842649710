import ReactDropzone from "react-dropzone";
import request from "superagent";
import React, { Fragment } from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "mdbreact";
import { Link } from "react-router-dom";
import MainHeader from "../components/core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class Edit extends React.Component {
  state = {
    selectedFile: null,
    isLoading: true,
    message: "",
    translationsGlobal: null,
    translationsNews: null
  };

  handleChange = event => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  onDrop = (acceptedFiles, rejectedFiles) => {
    const file = acceptedFiles.find(f => f);
    const i = new Image();

    i.onload = () => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.setState({
          selectedFile: {
            src: file.preview,
            width: i.width,
            height: i.height,
            data: reader.result
          }
        });
      };
    };

    i.src = file.preview;
  };

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "news/edit/" +
          this.props.match.params.code,
        {
          ...this.state
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        this.setState({
          message: response.data.message + " !"
        });
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  delete = ev => {
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "news/delete/" +
          this.props.match.params.code,
        {
          ...this.state
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        // handle success
        window.location.replace("/news");
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    if (this.state.isLoading === false) {
      const previewStyle = {
        display: "inline",
        width: "auto",
        height: 100
      };
      let form = [];
      switch (this.props.match.params.type) {
        case "news":
          form.push(
            <React.Fragment>
              <div className="mb-4">
                <h2>{this.state.translationsGlobal.title[currentLanguage]}</h2>
                <Input
                  value={this.state.title}
                  onChange={this.handleChange}
                  id="title"
                  name="title"
                />
              </div>
              <div className="mb-4">
                <h2 className="mb-4">
                  {this.state.translationsGlobal.image[currentLanguage]}
                </h2>
                <ReactDropzone accept="image/*" onDrop={this.onDrop}>
                  {this.state.translationsGlobal.fileDrop[currentLanguage]}
                </ReactDropzone>
                {this.state.selectedFile ? (
                  <Fragment>
                    <img
                      alt="Preview"
                      src={this.state.selectedFile.src}
                      height="100"
                      width="auto"
                      className="mt-2"
                    />
                  </Fragment>
                ) : (
                  <img
                    alt="Preview"
                    src={this.state.url}
                    height="100"
                    width="auto"
                    className="mt-2"
                  />
                )}
              </div>
              <div className="mb-4">
                <h2>
                  {this.state.translationsGlobal.content[currentLanguage]}
                </h2>
                <Input
                  type="textarea"
                  value={this.state.content}
                  onChange={this.handleChange}
                  id="content"
                  name="content"
                />
              </div>
            </React.Fragment>
          );
          break;
        case "medias":
          form.push(
            <React.Fragment>
              <div className="mb-4">
                <h2>{this.state.translationsGlobal.title[currentLanguage]}</h2>
                <Input
                  value={this.state.title}
                  onChange={this.handleChange}
                  id="title"
                  name="title"
                />
              </div>
              <div className="mb-4">
                <h2 className="mb-4">
                  {this.state.translationsGlobal.image[currentLanguage]}
                </h2>
                <ReactDropzone accept="image/*" onDrop={this.onDrop}>
                  {this.state.translationsGlobal.fileDrop[currentLanguage]}
                </ReactDropzone>
                {this.state.selectedFile ? (
                  <Fragment>
                    <img
                      alt="Preview"
                      src={this.state.selectedFile.src}
                      height="100"
                      width="auto"
                      className="mt-2"
                    />
                  </Fragment>
                ) : (
                  <img
                    alt="Preview"
                    src={this.state.url}
                    height="100"
                    width="auto"
                    className="mt-2"
                  />
                )}
              </div>
              <div className="mb-4">
                <h2>
                  {this.state.translationsGlobal.content[currentLanguage]}
                </h2>
                <Input
                  type="textarea"
                  value={this.state.content}
                  onChange={this.handleChange}
                  id="content"
                  name="content"
                />
              </div>
            </React.Fragment>
          );
          break;
        default:
          break;
      }
      return (
        <div id={"edit-" + this.props.match.params.type + " page"}>
          <MainHeader
            title={this.state.translationsNews.editNews[currentLanguage]}
          />
          <div id="edit-form">
            <div class="container">
              <Card>
                <CardBody>
                  <form onSubmit={this.handleSubmit}>
                    {form}
                    <div id="buttons">
                      <Button color="phode-green" type="submit">
                        Sauvegarder
                      </Button>
                      <Link to="/news">
                        <Button color="grey">Annuler</Button>
                      </Link>
                      <Button color="red" onclick={this.delete}>
                        Supprimer
                      </Button>
                    </div>
                  </form>
                </CardBody>
              </Card>
            </div>
          </div>
          <style jsx>{`
            #mainHeader {
              height: 125px;
            }
            h1 {
              margin-top: 30px;
            }
            h2 {
              margin-bottom: 0;
            }
            .md-form {
              margin: 0 !important;
            }
            #edit-form {
              padding: 50px 0;
            }
            textarea {
              padding: 0;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(
          process.env.REACT_APP_API_URL +
            "view/" +
            this.props.match.params.type +
            "/" +
            this.props.match.params.id
        ),
        axios.get(process.env.REACT_APP_API_URL + "translations/news"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global")
      ])
      .then(
        axios.spread((dataThis, dataTrNews, dataTrGlobal) => {
          this.setState({
            isLoading: false,
            ...dataThis.data,
            translationsNews: dataTrNews.data[0].translations,
            translationsGlobal: dataTrGlobal.data[0].translations
          });
        })
      )
      .catch(function(error) {
        console.log(error);
      });
  }
}

export default Edit;
