import React from "react";
import MainHeader from "../core/main/MainHeader";
import List from "../lists/List";

const Medias = () => (
  <div id="medias" className="page-list">
    <MainHeader title="medias" />
    <List type="medias" />
    <style jsx>{`
      #medias #listItems {
        padding: 20px;
      }
    `}</style>
  </div>
);

export default Medias;
