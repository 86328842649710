import Dropzone from "react-dropzone";
import queryString from "query-string";
import SelectDropdown from "../Select";
import React, { Fragment } from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "mdbreact";
import { Link } from "react-router-dom";
import MainHeader from "../core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");
const uid2 = require("uid2");
const moment = require("moment");

class DocumentCreate extends React.Component {
  state = {
    brands: "",
    customised: false,
    dateAdded: moment(),
    documentCategories: "",
    documentType: "",
    isLoading: true,
    message: "",
    createElement: null,
    species: "",
    translations: "",
    upload: null,
    size: {
      a3: false,
    },
  };

  filterValue = (filter, filterData) => {
    const filtersState = {
      brands: "brands",
      documentscategories: "documentCategories",
      species: "species",
      types: "documentType",
    };
    let filterKey = filtersState[filter];
    this.setState({
      [filterKey]: filterData,
    });
    console.log({ [filterKey]: filterData });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState({
      [event.target.name]: event.target.value,
    });

    if (event.target.name === "customised") {
      this.setState({
        customised: event.target.checked,
      });
    }
    if (event.target.name === "size") {
      this.setState({
        a3: event.target.checked,
      });
    }
  };

  onDrop = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          upload: {
            uploadData: fileAsBinaryString,
            uploadName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  handleSubmit = (ev) => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({
      message: "Saving...",
    });
    axios
      .post(
        process.env.REACT_APP_API_URL + "documents/create",
        {
          ...this.state,
          ...this.state.upload,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        window.location.replace("/documents");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    if (this.state.isLoading === false) {
      console.log(this.state);
      let info = [];
      info.push(
        <Fragment>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsGlobal.title[currentLanguage]}</h2>
            <Input
              value={this.state.title}
              onChange={this.handleChange}
              id="title"
              name="title"
            />
          </div>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsGlobal.code[currentLanguage]}</h2>
            {this.state.code}
          </div>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsDocuments.summary[currentLanguage]}</h2>
            <Input
              type="textarea"
              value={this.state.summary}
              onChange={this.handleChange}
              id="summary"
              name="summary"
            />
          </div>
          <div className="infoGroup mb-4">
            <h2>{this.state.translationsGlobal.file[currentLanguage]}</h2>
            <Dropzone onDrop={this.onDrop.bind(this)} multiple="false">
              {this.state.translationsGlobal.fileDrop[currentLanguage]}
            </Dropzone>
            {this.state.upload ? <p>{this.state.upload.uploadName}</p> : null}
          </div>
          <div className="infoGroup mb-4">
            <div className="align-items-center d-flex">
              <input
                className="mr-2"
                type="checkbox"
                id="customised"
                name="customised"
                ref="check_me"
                value={this.state.customised}
                onChange={this.handleChange}
              />
              <h2 className="m-0">
                {this.state.translationsDocuments.customised[currentLanguage]}
              </h2>
            </div>
          </div>
          <div className="infoGroup mb-4">
            <div className="align-items-center d-flex">
              <input
                className="mr-2"
                type="checkbox"
                id="a3"
                name="size"
                ref="check_me"
                value={this.state.a3}
                onChange={this.handleChange}
              />
              <h2 className="m-0">A3</h2>
            </div>
          </div>
          <div className="infoGroup mb-4">
            <h2>
              {this.state.translationsDocuments.language[currentLanguage]}
            </h2>
            <Input
              id="language"
              name="language"
              value={this.state.language}
              onChange={this.handleChange}
            />
          </div>
          <div id="buttons">
            <Button color="phode-green" type="submit">
              {this.state.translationsGlobal.save[currentLanguage]}
            </Button>
            <Link to="/documents">
              <Button color="grey">
                {this.state.translationsGlobal.cancel[currentLanguage]}
              </Button>
            </Link>
          </div>
          <br />
          {this.state.message}
        </Fragment>
      );

      return (
        <div id="document" className="page">
          <MainHeader title="Create" />
          <div id="pageContent">
            <Container>
              <form onSubmit={this.handleSubmit}>
                <Row>
                  <Col sm="8">
                    <Card>
                      <CardBody>{info}</CardBody>
                    </Card>
                  </Col>
                  <Col sm="4">
                    <Card className="mb-4">
                      <h3 className="p-4 text-white">Category*</h3>
                      <CardBody>
                        <SelectDropdown
                          type="documentscategories"
                          filterValue={this.filterValue}
                        />
                      </CardBody>
                    </Card>
                    <Card className="mb-4">
                      <h3 className="p-4 text-white">Filters*</h3>
                      <CardBody>
                        <br />
                        <h4 className="mb-2">Espèces</h4>
                        <SelectDropdown
                          type="species"
                          filterValue={this.filterValue}
                        />
                        <br />
                        <h4 className="mb-2">Marques</h4>
                        <SelectDropdown
                          type="brands"
                          filterValue={this.filterValue}
                        />
                        <br />
                        <h4 className="mb-2">Type de support</h4>
                        <SelectDropdown
                          type="types"
                          filterValue={this.filterValue}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </form>
            </Container>
          </div>
          <style jsx>{`
            h2 {
              font-size: 20px;
            }
            h3 {
              background: var(--blue);
              font-size: 20px;
            }
            #pageContent {
              padding: 50px 0;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    if (this.props.location.search) {
      this.setState({
        language: queryString.parse(this.props.location.search).lang,
      });
      if (this.props.location.search.includes("code")) {
        this.setState({
          code: queryString.parse(this.props.location.search).code,
        });
      } else {
        this.setState({
          code: "DOC" + uid2(6),
        });
      }
    } else {
      this.setState({
        code: "DOC" + uid2(6),
        language: currentLanguage,
      });
    }
    axios
      .all([
        axios.get(process.env.REACT_APP_API_URL + "brands"),
        axios.get(process.env.REACT_APP_API_URL + "types"),
        axios.get(process.env.REACT_APP_API_URL + "species"),
        axios.get(process.env.REACT_APP_API_URL + "documentscategories"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global"),
        axios.get(process.env.REACT_APP_API_URL + "translations/documents"),
      ])
      .then(
        axios.spread(
          (
            dataBrands,
            dataTypes,
            dataSpecies,
            dataDocumentsCategories,
            dataTrGlobal,
            dataTrDocuments
          ) => {
            this.setState({
              isLoading: false,
              author: Cookies.get("firstName") + " " + Cookies.get("lastName"),
              authorId: localStorage.getItem("extranet_phode_id"),
              brandsList: [...dataBrands.data],
              speciesList: [...dataSpecies.data],
              translationsGlobal: dataTrGlobal.data[0].translations,
              translationsDocuments: dataTrDocuments.data[0].translations,
            });
          }
        )
      )
      .catch(function (error) {
        console.log(error);
      });
  }
}

export default DocumentCreate;
