import React from "react";
import MainHeader from "../core/main/MainHeader";
import List from "../lists/List";

const Documents = () => (
  <div id="documents" className="page-list">
    <MainHeader title="documents" />
    <List type="documents" category="all" />
  </div>
);

export default Documents;
