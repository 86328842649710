import Dropzone from "react-dropzone";
import Select from "react-select";
import React, { Fragment } from "react";
import { Button, Card, CardBody, Col, Container, Input, Row } from "mdbreact";
import { Link } from "react-router-dom";
import MainHeader from "../../components/core/main/MainHeader";
import axios from "axios";
import Cookies from "js-cookie";
import uid2 from "uid2";
const currentLanguage = Cookies.get("lang");

class updateUsers extends React.Component {
  state = {
    isLoading: true,
    _id: null,
    content: null,
    title: null,
    message: "",
    upload: null,
  };

  avatar = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          avatar: {
            avatarData: fileAsBinaryString,
            avatarName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  companyLogo = (files) => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsBinaryString = reader.result;
        this.setState({
          companyLogo: {
            companyLogoData: fileAsBinaryString,
            companyLogoName: files[0].name,
          },
        });
      };
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.readAsBinaryString(file);
    });
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  delete = (ev) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "delete/users/" + this.state._id,
        {
          ...this.state,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // handle success
        window.location.replace("/users");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  handleSubmit = (ev) => {
    ev.preventDefault();
    Cookies.set("companyLogo", this.state.companyLogo.companyLogoName);
    axios.get(process.env.REACT_APP_API_URL + "list/users").then((result) => {
      console.log(result);
      console.log("Saving...");
      this.setState({
        message: "Saving...",
      });

      axios
        .post(
          process.env.REACT_APP_API_URL + "update/users/" + this.state._id,
          {
            ...this.state,
            ...this.state.avatar,
            ...this.state.companyLogo,
            email: this.state.email
              .toLowerCase()
              .replace(" ", "")
              .replace(/\s/g, ""),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setTimeout(() => {
            this.setState({
              message: response.data.message + " !",
            });
          }, 1000);
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        });
    });
  };

  delete = (ev) => {
    axios
      .post(
        process.env.REACT_APP_API_URL + "delete/users/" + this.state._id,
        {
          ...this.state,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // handle success
        window.location.replace("/users");
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  render() {
    if (this.state.isLoading === false) {
      return (
        <div id="document" className="page">
          <MainHeader
            title={
              this.state._id
                ? this.state.firstName + " " + this.state.lastName
                : this.state.translationsGlobal.createUser[currentLanguage]
            }
          />
          <div id="pageContent">
            <Container>
              <Card>
                <form onSubmit={this.handleSubmit}>
                  <h2 className="mb-3">
                    {this.state.translationsUsers.infoPerso[currentLanguage]}
                  </h2>
                  <table>
                    <tr>
                      <td>
                        {this.state.translationsUsers.avatar[currentLanguage]}
                        <div className="extension">*Format .JPG</div>
                      </td>
                      <Dropzone
                        onDrop={this.avatar.bind(this)}
                        multiple="false"
                      >
                        {
                          this.state.translationsGlobal.fileDrop[
                            currentLanguage
                          ]
                        }
                      </Dropzone>
                      {this.state.avatar ? (
                        <p>{this.state.avatar.avatarName}</p>
                      ) : null}
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.role[currentLanguage]}
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={this.state.role}
                          onChange={this.handleChange}
                          id="role"
                          name="role"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {
                          this.state.translationsUsers.nativeLanguage[
                            currentLanguage
                          ]
                        }
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={this.state.mainLanguage}
                          onChange={this.handleChange}
                          id="mainLanguage"
                          name="mainLanguage"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {
                          this.state.translationsUsers.firstname[
                            currentLanguage
                          ]
                        }
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={this.state.firstName}
                          onChange={this.handleChange}
                          id="firstName"
                          name="firstName"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.lastname[currentLanguage]}
                      </td>
                      <td>
                        <Input
                          type="text"
                          value={this.state.lastName}
                          onChange={this.handleChange}
                          id="lastName"
                          name="lastName"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.position[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.profession}
                          onChange={this.handleChange}
                          id="profession"
                          name="profession"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        {this.state.translationsUsers.phone[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.phoneOne}
                          onChange={this.handleChange}
                          id="phoneOne"
                          name="phoneOne"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.cell[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.phoneTwo}
                          onChange={this.handleChange}
                          id="phoneTwo"
                          name="phoneTwo"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.email[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.email}
                          onChange={this.handleChange}
                          id="email"
                          name="email"
                        />
                      </td>
                    </tr>
                  </table>
                  <h2 className="mt-3">
                    {this.state.translationsUsers.infoPro[currentLanguage]}
                  </h2>
                  <table>
                    <tr>
                      <td>
                        {
                          this.state.translationsUsers.companyName[
                            currentLanguage
                          ]
                        }
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.companyName}
                          onChange={this.handleChange}
                          id="companyName"
                          name="companyName"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.address[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.companyAddress}
                          onChange={this.handleChange}
                          id="companyAddress"
                          name="companyAddress"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.phone[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.companyPhone}
                          onChange={this.handleChange}
                          id="companyPhone"
                          name="companyPhone"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        {this.state.translationsUsers.country[currentLanguage]}
                      </td>
                      <td>
                        {" "}
                        <Input
                          type="text"
                          value={this.state.companyCountry}
                          onChange={this.handleChange}
                          id="companyCountry"
                          name="companyCountry"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        Logo<div className="extension">*Format .JPG</div>
                      </td>
                      <td>
                        <Dropzone
                          onDrop={this.companyLogo.bind(this)}
                          multiple="false"
                        >
                          {
                            this.state.translationsGlobal.fileDrop[
                              currentLanguage
                            ]
                          }
                        </Dropzone>
                        {this.state.companyLogo ? (
                          <p>{this.state.companyLogo.companyLogoName}</p>
                        ) : null}
                      </td>
                    </tr>
                  </table>
                  <div id="buttons" className="mt-5">
                    <Button
                      color={
                        this.state.email &&
                        this.state.mainLanguage &&
                        this.state.role
                          ? "phode-green"
                          : "grey"
                      }
                      disabled={
                        this.state.email &&
                        this.state.mainLanguage &&
                        this.state.role
                          ? null
                          : "disabled"
                      }
                      type="submit"
                    >
                      {this.state.translationsGlobal.save[currentLanguage]}
                    </Button>
                    <Link to={"/users"}>
                      <Button color="grey">
                        {this.state.translationsGlobal.cancel[currentLanguage]}
                      </Button>
                    </Link>
                    {Cookies.get("role") === "admin" ? (
                      <Button color="red" onClick={this.delete}>
                        {this.state.translationsGlobal.delete[currentLanguage]}
                      </Button>
                    ) : null}
                  </div>
                  <div id="messages">{this.state.message}</div>
                </form>
              </Card>
            </Container>
          </div>
          <style jsx>{`
            form {
              padding: 20px;
            }
            #pageContent {
              padding: 50px 0;
            }
            #messages {
              color: var(--green);
              padding: 20px 0;
            }
            #edit-menu a:after {
              content: "";
              display: block;
              height: 5px;
              width: 100%;
            }
            #edit-menu a.active {
              color: var(--green);
            }
            #edit-menu a.active:after {
              background: var(--green);
            }
            #edit-menu a {
              color: var(--grayText);
            }
            .extension {
              color: var(--green);
            }
            #edit-menu a:after {
              background: var(--grayText);
            }
            h2 {
              font-weight: bold;
            }
            #userMenu {
              border-bottom: 1px solid #f0f0f0;
              padding: 10px 0;
            }
            #userMenu a {
              color: black;
            }
            #userMenu li {
              margin-right: 15px;
            }
            table {
              width: 100%;
            }
            table td {
              border: 1px solid #f0f0f0;
              padding: 10px 20px;
            }
            table > tr > td:first-child {
              width: 20%;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    if (this.props.match.params.id !== "create") {
      axios
        .all([
          axios.get(
            process.env.REACT_APP_API_URL +
              "view/users/" +
              this.props.match.params.id
          ),
          axios.get(process.env.REACT_APP_API_URL + "translations/global"),
          axios.get(process.env.REACT_APP_API_URL + "translations/users"),
        ])
        .then(
          axios.spread((dataItem, dataTrGlobal, translationsUsers) => {
            console.log(dataItem.data);
            this.setState({
              isLoading: false,
              ...dataItem.data,
              companyLogo: {
                companyLogoName: dataItem.data.companyLogo,
              },
              avatar: {
                avatarName: dataItem.data.avatar,
              },
              translationsGlobal: dataTrGlobal.data[0].translations,
              translationsUsers: translationsUsers.data[0].translations,
            });
          })
        );
    } else {
      axios
        .all([
          axios.get(process.env.REACT_APP_API_URL + "translations/global"),
          axios.get(process.env.REACT_APP_API_URL + "translations/users"),
        ])
        .then(
          axios.spread((dataTrGlobal, translationsUsers) => {
            this.setState({
              companyLogo: {
                companyLogoName: "/users/E1rRbIAz-logo-phode.jpg",
              },
              _id: uid2(24),
              username: uid2(8),
              isLoading: false,
              translationsGlobal: dataTrGlobal.data[0].translations,
              translationsUsers: translationsUsers.data[0].translations,
            });
          })
        );
    }
  }
}

export default updateUsers;
