import { Link } from "react-router-dom";
import React, { Fragment } from "react";
import { Button, Card, CardBody, Input } from "mdbreact";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class Filters extends React.Component {
  state = {
    isLoading: true,
    message: "",
    translationsGlobal: null,
    translationsNews: null,

    brands: [],
    brandsNew: {
      label: "",
      value: ""
    },
    documentscategories: [],
    documentscategoriesNew: {
      EN: "",
      ES: "",
      FR: "",
      value: ""
    },
    mediacategories: [],
    mediacategoriesNew: {
      EN: "",
      ES: "",
      FR: "",
      value: ""
    },
    species: [],
    speciesNew: {
      EN: "",
      ES: "",
      FR: "",
      value: ""
    },
    types: [],
    typesNew: {
      EN: "",
      ES: "",
      FR: "",
      value: ""
    }
  };

  delete = (filter, position, event) => {
    const filterData = this.state[filter][position];
    axios
      .post(
        process.env.REACT_APP_API_URL +
          "filters/delete/" +
          filter +
          "/" +
          filterData._id,
        {
          ...filterData
        },
        {
          headers: {
            "Content-Type": "application/json"
          }
        }
      )
      .then(response => {
        window.location.replace("/filters");
      })
      .catch(error => {
        console.log(error);
      });
  };

  handleChange = (filter, lang, position, event) => {
    const value = event.target.value;
    const filterData = this.state[filter];
    if (filter === "brands") {
      filterData[position].label = value;
      this.setState(prevState => ({
        [filter]: [...filterData]
      }));
    } else {
      filterData[position][lang] = value;
      this.setState(prevState => ({
        [filter]: [...filterData]
      }));
    }
  };

  handleNew = (filter, lang, position, event) => {
    const value = event.target.value;
    if (filter === "brands") {
      this.setState(prevState => ({
        [filter + "New"]: {
          label: value,
          value: value.toLowerCase().replace(" ", "-")
        }
      }));
    } else {
      this.setState(prevState => ({
        [filter + "New"]: {
          EN: value,
          ES: "",
          FR: "",
          value: value.toLowerCase().replace(" ", "-")
        }
      }));
    }
  };

  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.setState({
      message: "Saving..."
    });
    const filters = [
      "brands",
      "documentscategories",
      "mediacategories",
      "species",
      "types"
    ];
    for (let i = 0; i < filters.length; i++) {
      const filtersItems = this.state[filters[i] + "New"];
      if (filtersItems.value) {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "filters/update/" +
              filters[i] +
              "/create",
            {
              ...filtersItems
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then(response => {})
          .catch(error => {
            console.log(error);
          });
      }
    }

    for (let i = 0; i < filters.length; i++) {
      const filtersItems = this.state[filters[i]];
      for (let y = 0; y < filtersItems.length; y++) {
        axios
          .post(
            process.env.REACT_APP_API_URL +
              "filters/update/" +
              filters[i] +
              "/" +
              filtersItems[y]._id,
            {
              ...filtersItems[y]
            },
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then(response => {})
          .catch(error => {
            console.log(error);
          });
      }
    }
    setTimeout(() => {
      window.location.replace("/filters");
    }, 5000);
  };

  render() {
    if (this.state.isLoading === false) {
      console.log(this.state);
      let filters = [
        "brands",
        "documentscategories",
        "mediacategories",
        "species",
        "types"
      ];
      let filtersData = {
        brands: [],
        documentscategories: [],
        mediacategories: [],
        species: [],
        types: []
      };
      for (let i = 0; i < filters.length; i++) {
        const filtersItems = this.state[filters[i]];
        for (let y = 0; y < filtersItems.length; y++) {
          if (filters[i] === "brands") {
            filtersData[filters[i]].push(
              <Fragment>
                <h3 className="align-items-center d-flex position-relative">
                  {filtersItems[y].value}
                  <Button
                    color="danger"
                    className="delete p-2 position-absolute rounded-circle"
                    onClick={this.delete.bind(this, filters[i], y)}
                  >
                    x
                  </Button>
                </h3>
                <li>
                  <Input
                    type="text"
                    value={this.state[filters[i]][y].label}
                    onChange={this.handleChange.bind(
                      this,
                      "brands",
                      "Global",
                      y
                    )}
                    id={filtersItems[y].value}
                    name={filtersItems[y].value}
                  />
                </li>
              </Fragment>
            );
          } else {
            filtersData[filters[i]].push(
              <Fragment>
                <h3 className="align-items-center d-flex position-relative">
                  {filtersItems[y].value}
                  <Button
                    color="danger"
                    className="delete p-2 position-absolute rounded-circle"
                    onClick={this.delete.bind(this, filters[i], y)}
                  >
                    x
                  </Button>
                </h3>
                <li>
                  <Input
                    type="text"
                    value={this.state[filters[i]][y].FR}
                    onChange={this.handleChange.bind(this, filters[i], "FR", y)}
                    id={filtersItems[y].value + "FR"}
                    name={filtersItems[y].value}
                  />
                </li>
                <li>
                  <Input
                    type="text"
                    value={this.state[filters[i]][y].EN}
                    onChange={this.handleChange.bind(this, filters[i], "EN", y)}
                    id={filtersItems[y].value + "EN"}
                    name={filtersItems[y].value}
                  />
                </li>
                <li>
                  <Input
                    type="text"
                    value={this.state[filters[i]][y].ES}
                    onChange={this.handleChange.bind(this, filters[i], "ES", y)}
                    id={filtersItems[y].value + "ES"}
                    name={filtersItems[y].value}
                  />
                </li>
              </Fragment>
            );
          }
        }
      }
      return (
        <form className="p-5" id="filters" onSubmit={this.handleSubmit}>
          <h3 className="pb-4 pt-4">Brands</h3>
          <Card>
            <CardBody className="p-4">
              <ul className="m-0 p-0">
                {filtersData.brands}
                <h4 className="new-item">New item</h4>
                <li>
                  <Input
                    type="text"
                    value={this.state.brandsNew.label}
                    onChange={this.handleNew.bind(
                      this,
                      "brands",
                      "Global",
                      "new"
                    )}
                    id={this.state.brandsNew.label.toLowerCase().trim()}
                    name={this.state.brandsNew.label.toLowerCase().trim()}
                  />
                </li>
              </ul>
            </CardBody>
          </Card>
          <h3 className="pb-4 pt-4">Documents Categories</h3>
          <Card>
            <CardBody className="p-4">
              <ul className="m-0 p-0">
                {filtersData.documentscategories}
                <li>
                  <h4 className="new-item">New item</h4>
                  <Input
                    type="text"
                    value={this.state.documentscategoriesNew.EN}
                    onChange={this.handleNew.bind(
                      this,
                      "documentscategories",
                      "EN",
                      "new"
                    )}
                    id={this.state.documentscategoriesNew.EN.toLowerCase().trim()}
                    name={this.state.documentscategoriesNew.EN.toLowerCase().trim()}
                  />
                </li>
              </ul>
            </CardBody>
          </Card>
          <h3 className="pb-4 pt-4">Media Categories</h3>
          <Card>
            <CardBody className="p-4">
              <ul className="m-0 p-0">{filtersData.mediacategories}</ul>
              <li>
                <h4 className="new-item">New item</h4>
                <Input
                  type="text"
                  value={this.state.mediacategoriesNew.EN}
                  onChange={this.handleNew.bind(
                    this,
                    "mediacategories",
                    "EN",
                    "new"
                  )}
                  id={this.state.mediacategoriesNew.EN.toLowerCase().trim()}
                  name={this.state.mediacategoriesNew.EN.toLowerCase().trim()}
                />
              </li>
            </CardBody>
          </Card>
          <h3 className="pb-4 pt-4">Species</h3>
          <Card>
            <CardBody className="p-4">
              <ul className="m-0 p-0">{filtersData.species}</ul>
              <li>
                <h4 className="new-item">New item</h4>
                <Input
                  type="text"
                  value={this.state.speciesNew.EN}
                  onChange={this.handleNew.bind(this, "species", "EN", "new")}
                  id={this.state.speciesNew.EN.toLowerCase().trim()}
                  name={this.state.speciesNew.EN.toLowerCase().trim()}
                />
              </li>
            </CardBody>
          </Card>
          <h3 className="pb-4 pt-4">Types</h3>
          <Card>
            <CardBody className="p-4">
              <ul className="m-0 p-0">{filtersData.types}</ul>
              <li>
                <h4 className="new-item">New item</h4>
                <Input
                  type="text"
                  value={this.state.typesNew.EN}
                  onChange={this.handleNew.bind(this, "types", "EN", "new")}
                  id={this.state.typesNew.EN.toLowerCase().trim()}
                  name={this.state.typesNew.EN.toLowerCase().trim()}
                />
              </li>
            </CardBody>
          </Card>
          <div className="pt-4" id="messages">
            {this.state.message}
          </div>
          <div className="pt-4">
            <Button color="phode-green" type="submit">
              {this.state.translationsGlobal.save[currentLanguage]}
            </Button>
          </div>

          <style jsx>{`
            .create {
              position: absolute;
              top: -25px;
              right: 0;
            }
            .create button {
              height: 40px;
              width: 40px;
            }
            .delete {
              height: 40px;
              right: 0;
              width: 40px;
            }
            #filters {
              min-height: 500px;
            }
            li {
              list-style-image: none;
              list-style-type: none;
            }
            .new-item {
              color: var(--green);
              margin-top: 40px;
            }
          `}</style>
        </form>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .all([
        axios.get(process.env.REACT_APP_API_URL + "list/brands"),
        axios.get(process.env.REACT_APP_API_URL + "list/documentscategories"),
        axios.get(process.env.REACT_APP_API_URL + "list/mediacategories"),
        axios.get(process.env.REACT_APP_API_URL + "list/species"),
        axios.get(process.env.REACT_APP_API_URL + "list/types"),
        axios.get(process.env.REACT_APP_API_URL + "translations/global")
      ])
      .then(
        axios.spread(
          (
            brands,
            documentscategories,
            mediacategories,
            species,
            types,
            translationsGlobal
          ) => {
            this.setState({
              isLoading: false,
              brands: brands.data.elements,
              documentscategories: documentscategories.data.elements,
              mediacategories: mediacategories.data.elements,
              species: species.data.elements,
              translationsGlobal: translationsGlobal.data[0].translations,
              types: types.data.elements
            });
          }
        )
      )
      .catch(function(error) {
        console.log(error);
      });
  }
}

export default Filters;
