import React, { Fragment } from "react";
import { Fa } from "mdbreact";

const DocumentType = props => {
  let type = props.type;
  switch (type) {
    case "pdf":
      type = type.replace("pdf", "file-pdf-o");
      break;
    default:
      type = type;
  }
  return (
    <Fragment>
      <i class={"fa white-text p-3 fa-" + type} />
      <style jsx>{`
        i {
          background: var(--blue);
          border-radius: 100%;
          font-size: 15px;
        }
      `}</style>
    </Fragment>
  );
};

export default DocumentType;
