import React from "react";
import Background from "../../../img/background.png";
import { Container } from "mdbreact";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class MainHeader extends React.Component {
  state = {
    isLoading: true,
    translationsGlobal: ""
  };

  render() {
    if (this.state.isLoading === false) {
      let title = "";
      if (
        this.props.title === "dashboard" ||
        this.props.title === "documents" ||
        this.props.title === "medias" ||
        this.props.title === "news" ||
        this.props.title === "users"
      ) {
        title = this.state.translationsGlobal[this.props.title][
          currentLanguage
        ];
      } else {
        title = this.props.title;
      }
      return (
        <div id="mainHeader" className="d-flex">
          <Container>
            <h1>{title}</h1>
          </Container>
          <img src={Background} />
          <style jsx>{`
            @media only screen and (max-width: 767px) {
              #mainHeader img {
                top: 0;
              }
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }

  componentDidMount() {
    axios
      .all([axios.get(process.env.REACT_APP_API_URL + "translations/global")])
      .then(
        axios.spread(dataTrGlobal => {
          this.setState({
            isLoading: false,
            translationsGlobal: dataTrGlobal.data[0].translations
          });
        })
      )
      .catch(function(error) {
        console.log(error);
      });
  }
}

export default MainHeader;
