import React from "react";
import { Row, Col } from "mdbreact";
import axios from "axios";
import Cookies from "js-cookie";
const currentLanguage = Cookies.get("lang");

class ListHeader extends React.Component {
  state = {
    isLoading: true,
    translationsGlobal: {},
    translationsDocuments: {},
    translationsUsers: {}
  };

  render() {
    if (this.state.isLoading === false) {
      const listHeader = [];
      switch (this.props.type) {
        case "documents":
          listHeader.push(
            <Row className="text-gray">
              <Col sm="1">{""}</Col>
              <Col sm="4">
                {this.state.translationsDocuments.title[currentLanguage]}
              </Col>
              <Col sm="4">
                {this.state.translationsDocuments.code[currentLanguage]}
              </Col>
              <Col sm="3">
                {this.state.translationsDocuments.added[currentLanguage]}
              </Col>
            </Row>
          );
          break;
        case "users":
          //listHeader.push(<></>);
          break;
        default:
          break;
      }
      if (this.props.type === "documents" || this.props.type === "users") {
        return <div id="listHeader">{listHeader}</div>;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
  componentDidMount() {
    const apiCalls = new Promise((resolve, reject) => {
      // **** TRANSLATIONS ****
      // Global
      axios
        .get(process.env.REACT_APP_API_URL + "translations/global")
        .then(response => {
          this.setState({
            translationsGlobal: response.data[0].translations
          });
          // Documents
          axios
            .get(process.env.REACT_APP_API_URL + "translations/documents")
            .then(response => {
              this.setState({
                translationsDocuments: response.data[0].translations
              });
              // Users
              axios
                .get(process.env.REACT_APP_API_URL + "translations/users")
                .then(response => {
                  this.setState({
                    translationsUsers: response.data[0].translations
                  });
                  resolve();
                })
                .catch(function(error) {
                  console.log(error);
                });
            })
            .catch(function(error) {
              console.log(error);
            });
        })
        .catch(function(error) {
          console.log(error);
        });
    });
    apiCalls
      .then(response => {
        this.setState({
          isLoading: false
        });
      })
      .catch(function(error) {
        console.log(error);
      });
  }
}

export default ListHeader;
