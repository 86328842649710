import axios from "axios";
import { Helmet } from "react-helmet";
import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Main from "./components/core/main/Main";
import { Row } from "reactstrap";
import Nav from "./components/core/Nav";
import Footer from "./components/core/Footer";
import "font-awesome/css/font-awesome.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "mdbreact/dist/css/mdb.css";
import "./App.css";
import Login from "./containers/Login";
import Password from "./containers/Password";
import styled from "styled-components";
import HttpsRedirect from "react-https-redirect";
import Cookies from "js-cookie";
if (process.env.REACT_APP_ENV === "DEV") require("dotenv").config();

class App extends Component {
  state = {
    burger: "burgerToClose",
    contentLeft: "col-sm-2",
    contentRight: "col-sm-10 offset-sm-2",
    rgpd: true,
    showMenu: false,
  };

  changeButtonState(event) {
    if (this.state.burger === "burgerToClose") {
      this.setState({
        burger: "burgerToOpen",
        contentLeft: "col-sm-1",
        contentRight: "col-sm-11 offset-sm-1",
      });
    } else {
      this.setState({
        burger: "burgerToClose",
        contentLeft: "col-sm-2",
        contentRight: "col-sm-10 offset-sm-2",
      });
    }
  }

  rgpd() {
    axios
      .get(
        process.env.REACT_APP_API_URL +
          "rgpd/update/" +
          localStorage.getItem("extranet_phode_id")
      )
      .then(function (response) {
        this.setState({
          rgpd: true,
        });
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
        window.location.replace("/dashboard");
      });
  }

  render() {
    return (
      <HttpsRedirect>
        <Router>
          <Wrapper>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Extranet - Groupe Phodé</title>
              <meta name="description" content="Welcome to Phodé Extranet" />
            </Helmet>
            <Route exact path="/" component={Login} />
            <Route path="/password/:id" component={Password} />
            {this.state.showMenu && (
              <>
                <Row>
                  <Nav
                    contentLeft={this.state.contentLeft}
                    burgerState={this.state.burger}
                  />

                  <div
                    id="content-right"
                    className={"pl-0 " + this.state.contentRight}
                  >
                    <Main
                      burgerClick={this.changeButtonState.bind(this)}
                      burgerState={this.state.burger}
                      className={this.burgerState}
                    />
                  </div>
                  <style jsx>{`
                    #nav {
                      z-index: 100;
                    }
                    #content-right {
                      z-index: 1;
                    }
                    #rgpd {
                      align-items: center;
                      background: url(/img/background.png);
                      display: flex;
                      height: 100vh;
                      position: fixed;
                      justify-content: center;
                      width: 100%;
                      z-index: 1000;
                    }
                    #rgpd-content {
                      background: rgba(255, 255, 255, 0.2);
                      color: white;
                      padding: 20px;
                    }

                    @media only screen and (max-width: 767px) {
                      #content-right {
                        padding-left: 15px !important;
                        position: initial;
                      }
                    }
                  `}</style>
                </Row>
                <Footer />
              </>
            )}
          </Wrapper>
        </Router>
      </HttpsRedirect>
    );
  }
  componentDidMount() {
    if (Cookies.get("token")) {
      this.setState({
        showMenu: true,
      });
    }

    if (window.location.pathname !== "/") {
      axios
        .get(
          process.env.REACT_APP_API_URL +
            "rgpd/check/" +
            localStorage.getItem("extranet_phode_id")
        )
        .then((response) => {
          // handle success
          if (response.data && response.data.rgpd === true) {
            this.setState({
              rgpd: true,
            });
          } else {
            this.setState({
              rgpd: false,
            });
          }
        })
        .catch(function (error) {
          // handle error
          console.log(error);
        })
        .then(function () {
          // always executed
        });
    }
  }
}

const Wrapper = styled.div`
  .btn {
    color: white;
  }
  /* MENU */
  .css-151xaom-placeholder {
    color: white;
  }
  .css-3esr4u-a11yText {
    background: white;
  }
  .css-kj6f9i-menu {
    background: white;
  }
`;

export default App;
