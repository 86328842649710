import React, { Fragment } from "react";
import Select from "react-select";
import axios from "axios";
import Cookies from "js-cookie";

class SelectDropdown extends React.Component {
  state = {
    isLoading: true,

    brands: [],
    brandsSelection: {
      data: []
    },
    documentscategories: [],
    documentscategoriesSelection: {
      data: []
    },
    mediatypes: [],
    mediatypesSelection: {
      data: []
    },
    types: [],
    typesSelection: {
      data: []
    },
    species: [],
    speciesSelection: {
      data: []
    }
  };

  filterChange = (filter, data) => {
    data = {
      data: {
        label: data.label,
        value: data.value
      },
      name: filter
    };

    this.setState({
      [filter + "Selection"]: data
    });
    this.props.filterValue(filter, data.data.value);
  };

  render() {
    if (this.state.isLoading === false) {
      return (
        <Select
          className="selectFilter"
          value={this.state[this.props.type + "Selection"].data}
          onChange={this.filterChange.bind(this, this.props.type)}
          options={this.state[this.props.type]}
        />
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    axios
      .get(process.env.REACT_APP_API_URL + "data/" + this.props.type)
      .then(response => {
        const filtersData = new Promise((resolve, reject) => {
          if (this.props.type !== "brands") {
            let filtersArray = [];
            for (let i = 0; i < response.data.length; i++) {
              filtersArray.push({
                label: response.data[i][Cookies.get("lang")],
                value: response.data[i].value
              });
            }
            resolve(filtersArray);
          } else {
            console.log(this.props.type);
            console.log(response.data);
            resolve(response.data);
          }
          if (reject) {
            console.log(reject);
          }
        });
        filtersData
          .then(response => {
            console.log(response);
            this.setState({
              isLoading: false,
              [this.props.type]: response
            });
            if (this.props.value) {
              let typeArray = this.state[this.props.type];
              for (let i = 0; i < typeArray.length; i++) {
                if (typeArray[i].value === this.props.value) {
                  let type = this.props.type + "Selection";
                  this.setState({
                    [type]: {
                      data: {
                        label: typeArray[i].label,
                        value: this.props.value
                      },
                      name: this.props.type
                    }
                  });
                }
              }
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      })
      .catch(function(error) {
        // handle error
        console.log(error);
      });
  }
}

export default SelectDropdown;
