import React from "react";
import { Button, Card, CardBody, Input } from "mdbreact";
import axios from "axios";
import Cookies from "js-cookie";

const setCookies = (response) => {
  return new Promise((resolve, reject) => {
    localStorage.setItem("extranet_phode_id", response.data._id);
    Cookies.set("firstName", response.data.firstName);
    Cookies.set("lastName", response.data.lastName);
    Cookies.set("username", response.data.username);
    Cookies.set("token", response.data.token);
    Cookies.set("role", response.data.role);
    Cookies.set("lang", response.data.lang);
    Cookies.set("companyAddress", response.data.companyAddress);
    Cookies.set("companyName", response.data.companyName);
    Cookies.set("companyLogo", response.data.companyLogo);
    Cookies.set("companyPhone", response.data.companyPhone);
    resolve();
  });
};

class Login extends React.Component {
  state = {
    isLoading: true,
    password: "",
    email: "",
    username: "",
    translations: "",
    activeLanguage: {
      value: "en",
      label: "EN",
    },
  };

  handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    const updatedState = {};
    updatedState[name] = value;
    this.setState(updatedState);
  };

  handleSubmit = (ev) => {
    ev.preventDefault();

    if (this.state.email) {
      const email = this.state.email.replace(" ", "").toLowerCase();

      axios
        .post(process.env.REACT_APP_API_URL + "users/log_in", {
          email,
          password: this.state.password,
        })
        .then(function (response) {
          if (response.data.token !== null) {
            setCookies(response).then(function () {
              window.location.href = "/dashboard";
            });
          } else {
            alert("Wrong username or password !");
          }
        })
        .catch(function (error) {
          alert("Wrong username or password !");
          console.log(error);
        });
    } else {
      alert("Enter an email");
    }
  };

  password = () => {
    if (this.state.email) {
      const email = this.state.email.replace(" ", "").toLowerCase();
      axios
        .post(process.env.REACT_APP_API_URL + "users/password/email", {
          email,
        })
        .then(function (response) {
          if (response.data.message) {
            alert(response.data.message);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      alert("Enter an email");
    }
  };

  render() {
    let currentLanguage = this.state.activeLanguage.label;
    if (this.state.isLoading === false) {
      console.log(this.state);
      return (
        <div
          id="front"
          className="align-items-center d-flex justify-content-center"
        >
          <form
            className="p-4 text-center text-white"
            onSubmit={this.handleSubmit}
          >
            <Card>
              <CardBody>
                <img src="/img/logo-slogan.png" />
                <p className="mt-5 text-center">
                  {this.state.translations.login.welcome[currentLanguage]}
                </p>
                <Input
                  label="Email"
                  type="email"
                  id="email"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
                <Input
                  label={
                    this.state.translations.login.password[currentLanguage]
                  }
                  type="password"
                  id="password"
                  name="password"
                  onChange={this.handleChange}
                  value={this.state.password}
                />
                <div id="buttons" className="d-flex mb-5">
                  <div id="remember" />
                  <div id="forgot">
                    <a className="text-white" onClick={this.password}>
                      {this.state.translations.login.forgot[currentLanguage]}
                    </a>
                  </div>
                </div>
                <div className="text-center">
                  <Button color="phode-green" type="submit">
                    {this.state.translations.login.connect[currentLanguage]}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </form>
          <style jsx>{`
            #front {
              background: url(/img/background.png);
              height: 100vh;
              width: 100%;
            }
            form {
              font-size: 15px;
              width: 450px;
            }
            input {
              color: white !important;
            }
            .md-form label {
              color: white;
            }
            .card {
              background: transparent;
            }
            .card-body {
              background: rgba(255, 255, 255, 0.2);
            }
            form img {
              margin: auto;
              width: 50%;
            }
            #buttons {
              font-size: 12px;
              justify-content: space-between;
            }
            #forgot a {
              text-decoration: underline;
            }
          `}</style>
          <style jsx global>{`
            #root > .row,
            #root footer {
              display: none;
            }
          `}</style>
        </div>
      );
    } else {
      return null;
    }
  }
  componentDidMount() {
    if (Cookies.get("token")) {
      window.location.replace("/dashboard");
    } else {
      axios
        .get(process.env.REACT_APP_API_URL + "translations/login")
        .then((response) => {
          this.setState({
            isLoading: false,
            translations: {
              login: response.data[0].translations,
            },
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }
}

export default Login;
